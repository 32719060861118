var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "7" } },
    [
      _c("v-autocomplete", {
        attrs: {
          "auto-select-first": "",
          label: _vm.parameter.display_name,
          items: _vm.reportDimensions,
          "item-value": "key_value",
          "item-text": "display_value",
          outlined: "",
          dense: "",
          "background-color": "white"
        },
        on: { change: _vm.addParameterValue },
        model: {
          value: _vm.selectValue,
          callback: function($$v) {
            _vm.selectValue = $$v
          },
          expression: "selectValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }