<template>
  <v-col cols="7">
    <v-autocomplete auto-select-first :label="parameter.display_name" :items="reportDimensions" v-model="selectValue"
      item-value="key_value" item-text="display_value" outlined dense background-color="white" @change="addParameterValue">
    </v-autocomplete>
  </v-col>
</template>

<script>
export default {
  name: 'InputSelect',

  props: ['parameter', 'reportDimensions'],

  data () {
    return {
      selectValue: ''
    }
  },

  methods: {
    addParameterValue () {
      this.$emit('add', {
        parameterName: this.parameter.name,
        parameterValue: this.selectValue
      })
    }
  }
}
</script>
